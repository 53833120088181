import React from "react";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Api from "../../modules/api";
import {NotificationManager} from "react-notifications";

class SourceForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameValue: '',
            typeValue: '',
            locationValue: ''
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        Api.post(`/api/new_config/${this.props.instanceId}/config/add_source`,
            {name: this.state.nameValue,
                type: this.state.typeValue,
                location: this.state.locationValue}).then(() => {
            NotificationManager.success("Successfully created new source", "Source Created");
            this.setState({
                nameValue: '',
                typeValue: '',
                locationValue: ''
            });
            this.props.onComplete();
            this.props.toggle();
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    render() {
        return (
            <Grid container spacing={2}>
                <Grid item xs={4}><label>Source Name:</label></Grid>
                <Grid item xs={8}><TextField name="nameValue" value={this.state.nameValue} onChange={this.handleInputChange} /></Grid>
                <Grid item xs={4}><label>Source Type:</label></Grid>
                <Grid item xs={8}>
                    <Select name="typeValue" value={this.state.typeValue} onChange={this.handleInputChange}>
                        {this.props.availableSourceTypes.map((value) => <MenuItem value={value} key={value}>{value}</MenuItem>)}
                    </Select>
                </Grid>
                <Grid item xs={4}><label>Location:</label></Grid>
                <Grid item xs={8}><TextField name="locationValue" value={this.state.locationValue} onChange={this.handleInputChange} /></Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={8}><Button onClick={this.handleSubmit} variant="contained">Submit</Button></Grid>
            </Grid>
        );
    }
}

export default SourceForm;