import React, {useState} from "react";
import PropTypes from "prop-types";
import { SessionContext } from "layouts/Dashboard.jsx";
import { NotificationManager } from "react-notifications";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import SelectWidget from "@rjsf/material-ui/lib/SelectWidget";
import Form from "@rjsf/material-ui";
import Button from "@material-ui/core/Button";
import validator from "@rjsf/validator-ajv8";
import {Card, CardContent} from "@material-ui/core";
import withI18n from "modules/withI18n";
import withStyles from "@material-ui/core/styles/withStyles";
import Api from "modules/api.js";
import applyNavs from "react-jsonschema-form-pagination";
import Zones from "./Zones.jsx";
import AlarmProfile from "./AlarmProfile.jsx";
import "../../assets/css/cartwatch_config.css";
import FormModal from "./FormModal";
import SourceForm from "./SourceForm";
import AppForm from "./AppForm";

function EditorWidget(props) {
  const { instanceName, config, type, selectOptions, triggerAlarm } = props.options._extra;
  const editorMap = {
    zones: Zones,
    alarm_profile: AlarmProfile
  };
  const [editor, toggleEditor] = useState(false);
  const [value, setValue] = useState(props.value);
  /* Fetching app name */
  let node = config;
  props.id.split("_").forEach(function (item) {
    if(!["root", type].includes(item) && Object.hasOwn(node, item)) { node = node[item]; }
  });
  if(!Object.hasOwn(node, "name")) {
    NotificationManager.warning(`App name for id ${props.id} not found, can't use ${type} editor.`, "Editor error");
  }
  const Editor = editorMap[type];
  let selectProps = {...props};
  selectProps.options.enumOptions = selectOptions;
  return (
    <>
      <div style={{display: "flex"}}>
        <div style={{flex: "90%"}}>
          <SelectWidget {...selectProps}
            onChange={(formData) =>
            {props.onChange(formData); setValue(formData); toggleEditor(false);}}
          />
        </div>
        <div style={{flex: "10%", whiteSpace: "nowrap"}}>
          <Button variant="outlined"
            color="secondary"
            style={{margin: "0.75em 0 0 0.75em", whiteSpace: "nowrap"}}
            onClick={() => {toggleEditor(!editor);}}>{editor ? "Close Editor" : "Open Editor"}</Button>
          {
            type === 'alarm_profile' &&
              <Button variant="outlined" color="secondary"
                      style={{margin: "0.75em 0 0 0.75em", whiteSpace: "nowrap"}}
                      onClick={() => {triggerAlarm(instanceName, node.name)}}
              >Trigger Alarms</Button>
          }
        </div>
      </div>
      {editor &&
                <Card style={{margin: "1em 0 1em 0", background: "#FCFCFC"}}>
                  <CardContent>
                    <Editor instanceName={instanceName} appName={node ? node.name : ''} fileName={value} />
                  </CardContent>
                </Card>}
    </>);
}

const onError = (errors) => {
  let error_alerts = new Map();
  for (const err of errors) {
    if (error_alerts.get(err.property) !== undefined &&
        error_alerts.get(err.property).indexOf(err.message) === -1)
      error_alerts.get(err.property).push(err.message);
    else
      error_alerts.set(err.property, [err.message]);
  }
  if (error_alerts.size > 0) {
    alert('Validation errors:' + Array.from(error_alerts.entries())
        .map(([key, value]) => `\n\nField ${key} errors:\n — ${value.join('\n — ')}`)
        .join(''));
  }
}

class CartwatchConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sourceModalToggle : false,
      appModalToggle : false,
      blockRendering: true,
    };
    this.reloadConfig = this.reloadConfig.bind(this);
    this.sourceModalPopUpHandler = this.sourceModalPopUpHandler.bind(this);
    this.appModalPopUpHandler = this.appModalPopUpHandler.bind(this);
  }

  sourceModalPopUpHandler=()=>{
    this.setState({
      sourceModalToggle: !this.state.sourceModalToggle
    })
  }

  appModalPopUpHandler=()=>{
    this.setState({
      appModalToggle: !this.state.appModalToggle
    })
  }

  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.loadData(this.props.context.instance_id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.context.instance_id !== prevProps.context.instance_id) {
      this.setState({blockRendering: true});
      if (typeof (this.props.context.instance_id) != "undefined") {
        this._isMounted && this.loadData(this.props.context.instance_id);
      }
    }
  }

  reloadConfig() {
    this._isMounted && this.loadData(this.props.context.instance_id);
  }

  loadData(instanceId) {
    if (instanceId === undefined) {
      return;
    }
    Api.get(`/api/new_config/${instanceId}/config/`).then((res) => {
      this.setState({
        blockRendering: false,
        config: res.config,
        configPath: res.config_path,
        configSchema: res.config_schema,
        availableZonesFiles: res.available_zones_files,
        availableProfilesFiles: res.available_profile_files,
        availableSources: res.available_sources,
        availableApps: res.available_apps,
        availableAppTypes: res.available_app_types,
        availableSourceTypes: res.available_source_types,
      });
    }).catch((e) => {
      NotificationManager.error(e.message, "Error loading Cartwatch config!");
    });
  }

  saveData(instanceId, data) {
    if (instanceId === undefined) {
      return;
    }
    Api.put(`/api/new_config/${instanceId}/config/`, data).then(() => {
      NotificationManager.success("Cartwatch config saved!");
    }).catch((e) => {
      NotificationManager.error(e.message, "Error saving Cartwatch config!");
    });
  }

  triggerAlarm(instanceId, app) {
    Api.put(`/api/new_config/${instanceId}/${app}/trigger_alarms/`).then((res) => {
      NotificationManager.success("Alarm for " + instanceId + ": " + app + " has been triggered!");
    }).catch((e) => {
      NotificationManager.error(e.message, "Error triggering alarms!");
    });
  }

  render() {
    if (this.state.blockRendering) {
      return (<h3>Loading...</h3>);
    }
    else {
      const uiSchema = {
        "ui:title": "Config path: " + this.state.configPath,
        base: {nav: "Base"},
        pipeline: {nav: "Pipeline"},
        tracking: {nav: "Tracking"},
        broker: {nav: "Broker"},
        sources: {
          nav: "Sources",
          "ui:options": {
            "addable": false,
            "removable": false,
          },
        },
        models: {nav: "Models"},
        apps: {
          nav: "Apps",
          "ui:options": {
            "addable": false,
            "removable": false,
          },
          items: {
            zones: {
              anyOf: [{
                "ui:widget": EditorWidget,
                "ui:options": {
                  "_extra": {
                    instanceName: this.props.context.instance_id,
                    config: this.state.config,
                    type: "zones",
                    selectOptions: this.state.availableZonesFiles.map((item) => {return {label: item, value: item};}),
                  }
                }
              }]
            },
            alarm_profile: {
              anyOf: [{
                "ui:widget": EditorWidget,
                "ui:options": {
                  "_extra": {
                    instanceName: this.props.context.instance_id,
                    config: this.state.config,
                    type: "alarm_profile",
                    selectOptions: this.state.availableProfilesFiles.map((item) => {return {label: item, value: item};}),
                    triggerAlarm: this.triggerAlarm,
                  }
                }
              }]
            }
          }
        },
        stats: {nav: "Stats"}
      };
      let FormWithPagination = applyNavs(Form);
      return (
        <div>
          <Button variant="contained" onClick={this.sourceModalPopUpHandler}>Add Source</Button>&nbsp;&nbsp;&nbsp;
          <FormModal open={this.state.sourceModalToggle}
                     toggle={this.sourceModalPopUpHandler}
                     title="Add Source"
                     content={<SourceForm instanceId={this.props.context.instance_id}
                                          availableSourceTypes={this.state.availableSourceTypes}
                                          toggle={this.sourceModalPopUpHandler}
                                          onComplete={this.reloadConfig}/>} />
          <Button variant="contained" onClick={this.appModalPopUpHandler}>Add App</Button>&nbsp;&nbsp;&nbsp;
          <FormModal open={this.state.appModalToggle}
                     toggle={this.appModalPopUpHandler}
                     title="Add App"
                     content={<AppForm instanceId={this.props.context.instance_id}
                                       availableSources={this.state.availableSources}
                                       availableApps={this.state.availableApps}
                                       availableAppTypes={this.state.availableAppTypes}
                                       toggle={this.appModalPopUpHandler}
                                       onComplete={this.reloadConfig}/>} />
          <FormWithPagination uiSchema={uiSchema}
                              title={this.state.configPath}
                              schema={this.state.configSchema}
                              formData={this.state.config}
                              validator={validator}
                              onError={onError}
                              experimental_defaultFormStateBehavior={{
                                emptyObjectFields: "populateRequiredDefaults",
                              }}
                              onSubmit={(e) => this.saveData(this.props.context.instance_id, e.formData)}/>
        </div>
      );
    }
  }
}

CartwatchConfig.propTypes = {
  classes: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
};

function withSessionContext(Component) {
  return function WrapperComponent(props) {
    return (
      <SessionContext.Consumer>
        {(state) => <Component {...props} context={state}/>}
      </SessionContext.Consumer>
    );
  };
}

export default withSessionContext(withI18n(withStyles(dashboardStyle)(CartwatchConfig)));
