import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Chip,
    Box
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Define styles
const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    tableContainer: {
        marginTop: theme.spacing(2),
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    successChip: {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.success.dark,
    },
    errorChip: {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.error.dark,
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    container: {
        padding: theme.spacing(2),
    }
}));

const AppsTable = ({ data = { apps: [] } }) => {
    const classes = useStyles();
    const allApps = data.apps;

    return (
        <Box className={classes.container}>
            {allApps.length > 0 ? (
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table className={classes.table} aria-label="apps table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allApps.map((app, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row" className={classes.capitalize}>
                                        {app.name}
                                    </TableCell>
                                    <TableCell>{app.type}</TableCell>
                                    <TableCell>
                                        <Chip
                                            label={app.enabled ? 'Enabled' : 'Disabled'}
                                            className={app.enabled ? classes.successChip : classes.errorChip}
                                            size="small"
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography variant="body1" color="textSecondary">
                    No apps found.
                </Typography>
            )}
        </Box>
    );
};

export default AppsTable;
