import React from "react";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 480,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

class FormModal extends React.Component {
    render() {
        if(this.props.open) {
            return (
                <Modal
                    open={this.props.open}
                    onClose={this.props.toggle}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <h3>{this.props.title}</h3>
                        {this.props.content}
                    </Box>
                </Modal>
            );
        } else {
            return <span/>
        }
    }
}

export default FormModal;
